.panorama-viewer-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}

.loading-progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ddd;
}

.loading-progress-bar {
  height: 5px;
  background-color: #007bff;
  transition: width 0.5s ease-in-out;
}

.loading-progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.4);
}
